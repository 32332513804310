'use client';

// #region Global Imports
import { useRouter } from 'next/router';
import React, { useRef, useState } from 'react';
// #endregion Global Imports
// #region Local Imports
import { inputHelpers } from '@components/Search/functions';
import { Spinner } from '@components/Spinner';
import { Switch } from '@components/Switch';
import { CONSTANTS } from '@constants';
import { AuthContext } from '@contexts/AuthContext';
import { getFragmentData } from '@generated/fragment-masking';
import { gql } from '@generated/gql';
import { renderBlockContentTranslation } from '@lib/func/renderBlockContentTranslation';
import type { IProps, Provider } from './LoginFrom';
// #endregion Local Imports

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
export const LoginForm: React.FC<IProps.LoginForm> = ({
  language,
  termLoginFormData: _termLoginFormData,
}) => {
  const router = useRouter();
  const synthForm = useRef<HTMLFormElement>(null);

  const { locale } = language;

  const termLoginFormData = getFragmentData(
    TermLoginFormFragment,
    _termLoginFormData,
  );

  const [state, setState] = useState({
    error: false,
    username: '',
    password: '',
    loggedIn: false,
    loading: false,
    provider: 'ts-auth',
  });

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    setState({
      ...state,
      loading: true,
    });

    event.preventDefault();

    const res = await fetch('/api/v1/auth/login', {
      method: 'POST',
      body: JSON.stringify({
        name: state.username,
        pass: state.password,
        provider: state.provider,
        locale,
      }),
    });

    if (res.ok) {
      setState({
        ...state,
        loggedIn: true,
        loading: false,
        error: true,
      });

      // Prevent error display.
      return void router.push(`/${locale || "de"}/?cache_reset=1`);
    }

    setState({
      ...state,
      loading: false,
      error: true,
    });
  };

  const {
    termLoginSubscriptionTitle,
    termLoginUserNameTrex,
    termLoginPasswordTrex,
    termLoginUserNameTs,
    termLoginPasswordTs,
    termLoginSubscriptionTs,
    termLoginSubscriptionTrex,
    termLoginFail,
  } = termLoginFormData;

  return (
    <AuthContext.Consumer>
      {(user): JSX.Element => (
        <div>
          <h1>Login</h1>
          {state.error ? (
            <p>
              {renderBlockContentTranslation(termLoginFail) || 'Login failed'}
            </p>
          ) : null}
          {user.token ? (
            <a className="Button__btn" href="/api/v1/auth/logout">
              Logout...
            </a>
          ) : (
            <form
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onSubmit={async (e): Promise<void> => {
                await handleSubmit(e);
              }}
            >
              <div>
                {renderBlockContentTranslation(termLoginSubscriptionTitle) ||
                  'Subscription Type'}
              </div>
              <Switch
                data={[
                  CONSTANTS.LOGIN.PROVIDER.TS_AUTH,
                  CONSTANTS.LOGIN.PROVIDER.DMPRO_AUTH,
                ]}
                label={[
                  renderBlockContentTranslation(termLoginSubscriptionTs) ||
                  'TREUHAND|SUISSE',
                  renderBlockContentTranslation(termLoginSubscriptionTrex) ||
                  'TREX Subscription',
                ]}
                onChange={(provider: Provider): void => {
                  if (provider === 'dmpro-auth') {
                    synthForm.current?.submit();
                  }

                  setState({
                    ...state,
                    provider,
                  });
                }}
                value={state.provider}
              />
              {state.provider ? (
                <>
                  <div>
                    {(state.provider === 'ts-auth'
                      ? renderBlockContentTranslation(termLoginUserNameTs)
                      : renderBlockContentTranslation(termLoginUserNameTrex)) ||
                      'Username'}
                  </div>
                  <input
                    disabled={state.loading || state.provider === 'dmpro-auth'}
                    onChange={(e): void =>
                      inputHelpers.handleInputChange(
                        'username',
                        e,
                        state,
                        setState,
                      )
                    }
                    placeholder={
                      (state.provider === 'ts-auth'
                        ? renderBlockContentTranslation(termLoginUserNameTs)
                        : renderBlockContentTranslation(
                          termLoginUserNameTrex,
                        )) || 'Username'
                    }
                    type="text"
                    value={state.username}
                  />
                  <div>
                    {(state.provider === 'ts-auth'
                      ? renderBlockContentTranslation(termLoginPasswordTs)
                      : renderBlockContentTranslation(termLoginPasswordTrex)) ||
                      'Password'}
                  </div>
                  <input
                    disabled={state.loading || state.provider === 'dmpro-auth'}
                    onChange={(e): void =>
                      inputHelpers.handleInputChange(
                        'password',
                        e,
                        state,
                        setState,
                      )
                    }
                    placeholder={
                      (state.provider === 'ts-auth'
                        ? renderBlockContentTranslation(termLoginPasswordTs)
                        : renderBlockContentTranslation(
                          termLoginPasswordTrex,
                        )) || 'Password'
                    }
                    type="password"
                    value={state.password}
                  />
                  <div>
                    <input
                      disabled={state.loading || state.provider === 'dmpro-auth'}
                      type="submit"
                      value="Login"
                    />
                  </div>
                  {state.provider === 'dmpro-auth' ? (
                    "The selected login method is deprecated. Please use the intended way. If not possible please contact the support. +41 (0)58 344 95 29"
                  ) : null}
                </>
              ) : null}
            </form>
          )}
          {state.loading ? <Spinner /> : null}
          <form
            action={`${String(process.env.NEXT_PUBLIC_BACKEND)}/${String(language.locale)}`}
            hidden
            id="openid-connect-login-form"
            method="POST"
            ref={synthForm}
          >
            <input name="trex_abo" value="Log in with TREX-Abo" />
            <input
              name="form_build_id"
              value="form-sVSyG9rhMiGlpHoXUXfn2Ala33jIlzRLCsI4xlokCv0"
            />
            <input name="form_id" value="openid_connect_login_form" />
          </form>
        </div>
      )}
    </AuthContext.Consumer>
  );
};
/* eslint-enable @typescript-eslint/no-unsafe-assignment */

export const TermLoginFormFragment = gql(/* GraphQL */ `
  fragment TermLoginFormFragment on Query {
    termLoginUserNameTrex: blockContentQuery(
      filter: {
        conjunction: AND
        conditions: [
          { field: "type", value: "translations", language: $language }
          { field: "info", value: "Login Form username TREX" }
        ]
      }
    ) {
      translation: entities {
        value: entityTranslation(language: $language) {
          ... on BlockContentTranslations {
            text: fieldPlaintext
          }
        }
      }
    }

    termLoginPasswordTrex: blockContentQuery(
      filter: {
        conjunction: AND
        conditions: [
          { field: "type", value: "translations", language: $language }
          { field: "info", value: "Login Form Password TREX" }
        ]
      }
    ) {
      translation: entities {
        value: entityTranslation(language: $language) {
          ... on BlockContentTranslations {
            text: fieldPlaintext
          }
        }
      }
    }

    termLoginUserNameTs: blockContentQuery(
      filter: {
        conjunction: AND
        conditions: [
          { field: "type", value: "translations", language: $language }
          { field: "info", value: "Login Form Username TreuhandSuisse" }
        ]
      }
    ) {
      translation: entities {
        value: entityTranslation(language: $language) {
          ... on BlockContentTranslations {
            text: fieldPlaintext
          }
        }
      }
    }

    termLoginPasswordTs: blockContentQuery(
      filter: {
        conjunction: AND
        conditions: [
          { field: "type", value: "translations", language: $language }
          { field: "info", value: "Login Form Password TreuhandSuisse" }
        ]
      }
    ) {
      translation: entities {
        value: entityTranslation(language: $language) {
          ... on BlockContentTranslations {
            text: fieldPlaintext
          }
        }
      }
    }

    termLoginSubscriptionTitle: blockContentQuery(
      filter: {
        conjunction: AND
        conditions: [
          { field: "type", value: "translations", language: $language }
          { field: "info", value: "Login Form Subscription Title" }
        ]
      }
    ) {
      translation: entities {
        value: entityTranslation(language: $language) {
          ... on BlockContentTranslations {
            text: fieldPlaintext
          }
        }
      }
    }

    termLoginSubscriptionTs: blockContentQuery(
      filter: {
        conjunction: AND
        conditions: [
          { field: "type", value: "translations", language: $language }
          { field: "info", value: "Login Form Abo Selector TreuhandSuisse" }
        ]
      }
    ) {
      translation: entities {
        value: entityTranslation(language: $language) {
          ... on BlockContentTranslations {
            text: fieldPlaintext
          }
        }
      }
    }

    termLoginSubscriptionTrex: blockContentQuery(
      filter: {
        conjunction: AND
        conditions: [
          { field: "type", value: "translations", language: $language }
          { field: "info", value: "Login Form Abo Selector TREX" }
        ]
      }
    ) {
      translation: entities {
        value: entityTranslation(language: $language) {
          ... on BlockContentTranslations {
            text: fieldPlaintext
          }
        }
      }
    }

    termLoginFail: blockContentQuery(
      filter: {
        conjunction: AND
        conditions: [
          { field: "type", value: "translations", language: $language }
          { field: "info", value: "Login Form Fail" }
        ]
      }
    ) {
      translation: entities {
        value: entityTranslation(language: $language) {
          ... on BlockContentTranslations {
            text: fieldPlaintext
          }
        }
      }
    }
  }
`);
