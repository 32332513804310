export const CONSTANTS = {
  SEARCH: {
    FORMAT: 'DD.MM.YYYY',
    MONTHS: [
      '1 ·',
      '2 ·',
      '3 ·',
      '4 ·',
      '5 ·',
      '6 ·',
      '7 ·',
      '8 ·',
      '9 ·',
      '10 ·',
      '11 ·',
      '12 ·',
    ],
    EMPTY_SEARCH: {
      text: '',
      author: '',
      section: 'thema',
      dateMin: '1950-01-01',
      dateMax: '2050-01-01',
      category: '',
      tag: '',
      year: '',
      issue: '',
      page: 0,
    },
  },
  LOGIN: {
    PROVIDER: {
      TS_AUTH: 'ts-auth',
      DMPRO_AUTH: 'dmpro-auth',
    },
  },
};
