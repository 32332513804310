import pickBy from 'lodash/pickBy';
import type React from 'react';
import type { FormEvent } from 'react';
import type { NextRouter } from 'next/dist/client/router';
import { CONSTANTS } from '@constants';

export const inputHelpers = {
  handleInputChange: <T>(
    key: string,
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>,
    state: T,
    setState: (state: T) => void,
  ): void => {
    setState({
      ...state,
      [key]: event.target.value,
    });
  },
  handleSubmit: async <
    T extends {
      text?: string;
      author?: string;
      category?: string;
      tag?: string;
      issue?: string;
      dateMin?: string;
      dateMax?: string;
      year?: string;
    },
  >(
    event: React.ChangeEvent<HTMLInputElement> | FormEvent<HTMLFormElement>,
    state: T,
    router: NextRouter,
  ): Promise<void> => {
    event.preventDefault();
    await router.replace({
      query: pickBy({
        ...router.query,
        text: state.text,
        author: state.author,
        category: state.category,
        tag: state.tag,
        year: state.year,
        issue: state.issue,

        // NOTE: Reset Dates on placeholder value.
        dateMin:
          state.dateMin === CONSTANTS.SEARCH.EMPTY_SEARCH.dateMin
            ? undefined
            : state.dateMin,
        dateMax:
          state.dateMax === CONSTANTS.SEARCH.EMPTY_SEARCH.dateMax
            ? undefined
            : state.dateMax,

        // NOTE: Reset page.
        page: 0,
      }),
    });
  },
};
