import * as React from 'react';
import styles from './styles.module.sass';

interface SwitchProps {
  value: string;
  label: string[];
  data: string[];
  onChange: any;
}

export const Switch: React.FC<SwitchProps> = ({
  value,
  label,
  data,
  onChange,
}) => (
  <div className={styles.Switch}>
    {label.map((item: string, index: number) => (
      <div className={styles.Switch__Item} key={data[index]}>
        <input
          checked={data[index] === value}
          id={`SwitchId${index}`}
          name={`Switch${index}`}
          onChange={(event): void => {
            onChange(event.target.value);
          }}
          required={value === ''}
          type="radio"
          value={data[index]}
        />
        <label htmlFor={`SwitchId${index}`}>{item}</label>
      </div>
    ))}
  </div>
);
