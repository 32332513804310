// #region Global Imports
import type { GetServerSideProps, NextPage } from 'next/types';
import * as React from 'react';
// #endregion Global Imports
// #region Local Imports
import { useQuery } from '@apollo/client';
import { Layout } from '@components/Layout';
import type { TermLoginFormFragment } from '@components/LoginForm';
import { LoginForm } from '@components/LoginForm';
import { Spinner } from '@components/Spinner';
import { WrapperContent } from '@components/Wrapper';
import type { FragmentType } from '@generated/fragment-masking';
import { gql } from '@generated/gql';
import { LoginFormPageQueryDocument } from '@generated/graphql';
import { withSession } from '@lib/auth';
import {
    addApolloState,
    initializeApollo,
    withDefaultVariables,
} from '@services/apollo';
import type { IPages } from '../types';
// #endregion Local Imports

export const LoginFormPageQuery = gql(/* GraphQL */ `
  query LoginFormPageQuery(
    $language: LanguageId!
    $path: String!
    $translate: LanguageId!
  ) {
    ...LayoutFragment
    ...TermLoginFormFragment
  }
`);

const Login: NextPage<IPages.Login> = ({ variables, user, language }) => {
    const { data, loading } = useQuery(LoginFormPageQueryDocument, {
        variables,
    });

    if (loading) return <Spinner />;

    return (
        <Layout
            data={data}
            language={language}
            title="Login"
            user={user ?? undefined}
        >
            <WrapperContent>
                <LoginForm
                    language={language}
                    termLoginFormData={data as FragmentType<typeof TermLoginFormFragment>}
                />
            </WrapperContent>
        </Layout>
    );
};

export const getServerSideProps: GetServerSideProps = withSession(
    async (context) => {
        const apolloClient = initializeApollo(null, context.req.jwt);
        const variables = withDefaultVariables(context, {});

        await apolloClient.query({
            query: LoginFormPageQueryDocument,
            variables,
        });

        return addApolloState(context, apolloClient, {
            props: {
                variables,
            },
        });
    },
);

export default Login;
